import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';

import { ReactComponent as ColorIcon } from './images/color.svg';

import styles from './Item.module.css';

const Item = inject('store')(
    observer(
        class Item extends Component {
            state = {
                color: null,
            };

            render() {
                const { resourceId, showParent, rootFolderName, store } = this.props;

                const itemDefinition = store.resourceNameData[resourceId];
                const isSelected = resourceId in store.selectedResources;

                let itemName = showParent ? itemDefinition.fullName : itemDefinition.name;
                if (rootFolderName === 'Cold Circle' && itemName === 'Cold') {
                    itemName += ' (generation)';
                }

                let selectedStyle = null;
                if (isSelected) {
                    selectedStyle = {
                        backgroundColor: store.selectedResources[resourceId].color,
                    };
                }

                return (
                    <div className={styles.HelpItem}>
                        <div
                            className={cx(styles.Item, { [styles.SelectedItem]: isSelected })}
                            onClick={() => store.toggleSelection(resourceId)}
                        >
                            <span className={styles.ItemBullet} style={selectedStyle} />
                            {itemName}
                        </div>
                        {this.props.disableHelp && (
                            <ColorIcon
                                className={styles.ColorIcon}
                                onClick={() => this.colorInput.click()}
                            />
                        )}
                        <input
                            type="color"
                            className={styles.ColorInput}
                            ref={(ref) => (this.colorInput = ref)}
                            onChange={(e) =>
                                this.props.store.updateColor(this.props.resourceId, e.target.value)
                            }
                            value={isSelected && store.selectedResources[resourceId].color}
                        />
                    </div>
                );
            }
        },
    ),
);

export default Item;
