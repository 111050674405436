import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import TopBarMenu from '../TopBarMenu/TopBarMenu';

import { ReactComponent as ShareIcon } from './images/share.svg';

import styles from './ShareMenu.module.css';

const ShareMenu = inject('store')(observer(class ShareMenu extends Component {
    state = {
        anchorEl: null,
    };

    urlRef = React.createRef();

    copyUrlToClipboard = (e) => {
        this.urlRef.current.select();
        document.execCommand('copy');

        this.setState({ anchorEl: e.currentTarget });
    };

    render() {
        const shareUrl = `https://energydata-faculties.utwente.realised.nl/?view=${this.props.store.serializeView}`;

        return (
            <TopBarMenu
                buttonIcon={ShareIcon}
                buttonText={'Share'}
                disabled={!this.props.store.hasSelectedResources}
            >
                <div className={styles.ShareMenu}>
                    <div className={styles.ShareText}>
                        Copy the URL below to share the current view:
                    </div>
                    <div className={styles.TextAreaContainer}>
                        <input
                            type="text"
                            className={styles.TextArea}
                            ref={this.urlRef}
                            value={shareUrl}
                            readOnly
                        />
                        <div className={styles.CopyButton} onClick={this.copyUrlToClipboard}>
                            <ShareIcon className={styles.CopyButtonIcon} />
                        </div>
                    </div>
                </div>
            </TopBarMenu>
        );
    }
}));

export default ShareMenu;
