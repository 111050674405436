import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';

import styles from './TopBarMenu.module.css';
import { ReactComponent as ArrowIcon } from './images/arrow.svg';

const TopBarMenu = ({ buttonIcon, buttonText, disabled, children }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const menuElement = useRef(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false);

        return () => {
            document.removeEventListener('mousedown', handleClick, false)
        }
    })

    useEffect(() => {
        if (disabled && menuOpen) {
            setMenuOpen(!menuOpen);
        }
    }, [disabled]);

    const toggleMenu = () => !disabled && setMenuOpen(!menuOpen);

    const handleClick = e => {
        if (menuElement.current.contains(e.target)) {
            return;
        }

        setMenuOpen(false);
    }

    return (
        <div className={styles.TopBarMenu} ref={menuElement}>
            <div
                className={cx(styles.Button, { [styles.ButtonActive]: menuOpen, [styles.ButtonDisabled]: disabled })}
                onClick={toggleMenu}>
                { React.createElement(buttonIcon, { className: styles.ButtonIcon }) }
                <span className={styles.ButtonText} >{ buttonText }</span>
                <ArrowIcon className={styles.ButtonArrow} />
            </div>
            <div
                className={cx(styles.MenuContent, { [styles.MenuHidden]: !menuOpen })}
            >
                { children }
            </div>
        </div>
    );
}

export default TopBarMenu;