import React from 'react';

import { ReactComponent as UtLogo } from './images/ut-logo.svg';

import styles from './SmallScreen.module.css';

const message = {
    error: 'Something went wrong, please try reloading the page.',
    info: 'Your screen is too small to display the application correctly. Please resize your browser or try using landscape mode.'
}

const SmallScreen = (props) => {
    return (
        <div className={styles.SmallScreen}>
            <UtLogo className={styles.Logo} />
            <span className={styles.Text}>
                {props.hasError ? message.error : message.info}
            </span>
        </div>
    );
}

export default SmallScreen;