import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Item from '../Folders/Item/Item';

import styles from './Legend.module.css';

const Legend = inject('store')(observer(class Legend extends Component {
    render() {
        const { store } = this.props;

        return (
            <div className={styles.Legend}>
                { Object.keys(store.selectedResources).map(resourceId => <Item key={resourceId} resourceId={resourceId} showParent disableHelp />)}
            </div>
        );
    }
}));

export default Legend;