import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';

import Loader from '../components/Loader/Loader';
import Legend from './Legend/Legend';
import Folders from './Folders/Folders';

import styles from './Sidebar.module.css';

const Sidebar = inject('store')(observer(class Sidebar extends Component {
    componentDidMount() {
        this.props.store.fetchResources();
    }
    
    render() {
        const { store, className } = this.props;
        
        return (
            <nav className={cx(className, styles.Sidebar)}>
                { Object.keys(store.selectedResources).length > 0 && <Legend/> }
                { store.resourceStructure.length > 0 ? (
                    <div className={styles.FoldersContainer}>
                        { store.resourceStructure.map(folders => <Folders key={folders.name} name={folders.name} children={folders.children} />)}
                    </div>
                ) : <Loader text={'Loading resources...'} color={'#fff'} size={1} /> }
            </nav>
        );
    }
}));

export default Sidebar;
