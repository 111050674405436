import React from 'react';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';
import ReactAppzi from 'react-appzi';

import TopBar from './TopBar/TopBar';
import Sidebar from './Sidebar/Sidebar';
import Chart from './Chart/Chart';
import SmallScreen from './SmallScreen/SmallScreen';

import styles from './App.module.css';

const App = inject('store')(
    observer(
        class App extends React.Component {
            state = {
                largeEnough: false,
                hasError: false,
            };

            componentDidCatch() {
                this.setState({ hasError: true });
            }

            componentDidMount() {
                this.screenLargeEnough(document.documentElement.clientWidth);

                window.addEventListener('resize', this.updateDimension);
            }

            updateDimension = () => {
                this.screenLargeEnough(document.documentElement.clientWidth);
            };

            screenLargeEnough = (width) => {
                const largeEnough = width > 706;
                this.setState({ largeEnough });
            };

            render() {
                ReactAppzi.initialize('GXWAM');

                return this.state.largeEnough && !this.state.hasError ? (
                    <div
                        className={cx(styles.App, { [styles.Blurred]: this.props.store.showModal })}
                    >
                        <TopBar className={styles.TopBar} />
                        <Sidebar className={styles.Sidebar} />
                        <Chart className={styles.Chart} />
                    </div>
                ) : (
                    <SmallScreen hasError={this.state.hasError} />
                );
            }
        },
    ),
);

export default App;
