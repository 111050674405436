class Api {
    static httpGetRequest = async (endpoint) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}`)

            const resultJson = await result.json();
            
            // Todo: handle errors
            return resultJson.results;
        } catch(error) {
            return null;
        }
    }
}

export default Api;