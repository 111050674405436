import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import TopBarMenu from '../TopBarMenu/TopBarMenu';

import styles from './ExportMenu.module.css';
import { ReactComponent as DownloadIcon } from './images/download.svg';
import { ReactComponent as CsvIcon } from './images/csv.svg';
import { ReactComponent as JsonIcon } from './images/json.svg';

const ExportMenu = inject('store')(observer(class ExportMenu extends Component {
    downloadFile = (data, fileExtension, mimeType) => {
        const element = document.createElement("a");
        const file = new Blob([data], {type: mimeType});
        element.href = URL.createObjectURL(file);
        element.download = `utwente-energydata-${moment().format("YYYY-MM-DDTHHmmss")}.${fileExtension}`;
        element.click();
    }

    exportJson = () => {
        this.downloadFile(JSON.stringify(this.props.store.exportData), 'json', 'application/json')
    }

    exportCsv = () => {
        let csvFiles = [];
        this.props.store.exportData.forEach((resource, index) => {
            let csv = resource.data.map(row => Object.keys(resource.data[0]).map(fieldName => {
                return fieldName.startsWith('x') ? moment(row[fieldName]).tz('Europe/Amsterdam').format('YYYY-MM-DD HH:mm:ss') : row[fieldName];
            }).join(','))

            csv.unshift(`x${index} (${resource.label}),y${index} (${resource.unit})`);

            csvFiles.push(csv)
        })

        let output = [];
        for (let i = 0; i < csvFiles[0].length; i++) {
            output.push(csvFiles.map(file => file[i]).join(','));
        }

        let csvFile = output.join('\r\n')
        this.downloadFile(csvFile, 'csv', 'text/csv')
    }

    render() {
        return (
            <TopBarMenu buttonIcon={DownloadIcon} buttonText={"Export"} disabled={!this.props.store.hasSelectedResources}>
                <div className={styles.ExportMenu}>
                    <div className={styles.ExportButton} onClick={this.exportJson}>
                        <JsonIcon className={styles.ExportButtonIcon} />
                        <div className={styles.ExportButtonText}>
                            Export as JSON
                        </div>
                    </div>
                    <div className={styles.ExportButton} onClick={this.exportCsv}>
                        <CsvIcon className={styles.ExportButtonIcon} />
                        <div className={styles.ExportButtonText}>
                            Export as CSV
                        </div>
                    </div>
                </div>
            </TopBarMenu>
        );
    }
}));

export default ExportMenu;