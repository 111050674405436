import React from 'react';
import { inject, observer } from 'mobx-react';

import Folder from './Folder/Folder';
import Item from './Item/Item';

import styles from './Folders.module.css';

const Folders = inject('store')(
    observer(
        class Folders extends React.Component {
            renderChildren(children) {
                return children.map((child) =>
                    typeof child === 'object'
                        ? this.generateFolder(child)
                        : this.generateItem(child),
                );
            }

            generateFolder(folder) {
                return (
                    <Folder key={folder.name} name={folder.name}>
                        {this.renderChildren(folder.children)}
                    </Folder>
                );
            }

            generateItem(resourceId) {
                return (
                    <Item
                        key={resourceId}
                        resourceId={resourceId}
                        rootFolderName={this.props.name}
                    />
                );
            }

            render() {
                const { name, children } = this.props;

                return (
                    <div className={styles.Folders}>
                        <div className={styles.FoldersHeader}>{name}</div>
                        <div className={styles.FoldersContainer}>
                            {this.renderChildren(children)}
                        </div>
                    </div>
                );
            }
        },
    ),
);

export default Folders;
