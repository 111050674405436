import React from 'react';
import cx from 'classnames';

import styles from './Selector.module.css';

const Selector = ({ className, options, selectedValue, onClick }) => (
    <div className={cx(styles.Selector, className)}>
        { options.map(option => 
            <div className={cx(styles.Option, { [styles.SelectedOption]: option === selectedValue })} key={option} onClick={() => onClick(option)}> 
                <div className={styles.OptionBullet} />
                {option}
            </div>
        ) }
    </div>
);

export default Selector;