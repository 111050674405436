import React, { useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';
import 'chartjs-adapter-moment';
import { Bar } from 'react-chartjs-2';

import styles from './Chart.module.css';
import { ReactComponent as BarIcon } from './images/bar.svg';

const generateChartOptions = (chartAxes, resolution) => {
    const tooltipTimeFormats = {
        year: 'YYYY',
        month: 'MMMM YYYY',
        day: 'MMMM DD, YYYY',
        hour: 'MMMM DD, YYYY HH:mm',
    };

    const yScales = Object.fromEntries(
        Array.from(chartAxes).map((unit, index) => [
            unit,
            {
                type: 'linear',
                position: index % 2 === 0 ? 'left' : 'right',
                grid: {
                    borderDash: [5, 5],
                },
                ticks: {
                    callback: (value) => `${value.toLocaleString('nl-NL')} ${unit}`,
                },
                beginAtZero: true,
            },
        ]),
    );

    // Generate chart options
    const chartOptions = {
        animation: {
            duration: 500,
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                titleAlign: 'center',
                usePointStyle: true,
                cornerRadius: 5,
                backgroundColor: 'rgb(53, 57, 62)',
                titleColor: '#f0f1f2',
                titleFont: {
                    size: 14,
                    family: "'Univers Next W02', 'Helvetica', 'Arial', sans-serif",
                    lineHeight: 1.8,
                },
                bodyFont: {
                    size: 14,
                    family: "'Univers Next W02', 'Helvetica', 'Arial', sans-serif",
                },
                xAlign: 'center',
                yAlign: 'bottom',
                callbacks: {
                    label: (context) => {
                        return `  ${context.dataset.label}: ${new Intl.NumberFormat('nl-NL').format(
                            context.parsed.y,
                        )} ${context.dataset.yAxisID}`;
                    },
                },
            },
        },
        layout: {
            padding: 30,
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    tooltipFormat: tooltipTimeFormats[resolution],
                    unit: resolution,
                    displayFormats: {
                        hour: 'HH:mm',
                    },
                },
                grid: {
                    display: false,
                },
            },
            ...yScales,
        },
    };

    return chartOptions;
};

// Only change options when axes change?
const Chart = inject('store')(
    observer(({ className, store }) => {
        const { chartData, chartAxes, resolution } = store;

        const chartOptions = useMemo(
            () => generateChartOptions(chartAxes, resolution),
            [chartAxes, resolution],
        );

        return (
            <div className={cx(className, styles.Chart)}>
                <div className={styles.ChartContainer}>
                    {chartData.datasets.length ? (
                        <Bar data={chartData} options={chartOptions} />
                    ) : (
                        <div className={styles.EmptyChartFallback}>
                            <BarIcon className={styles.barIcon} />
                            <h1>View your charts here</h1>
                            <span>Select a chart from the sidebar to get started</span>
                        </div>
                    )}
                </div>
            </div>
        );
    }),
);

export default Chart;
