import React from 'react';

import styles from './Loader.module.css';

const defaultSize = 2;

const Loader = props => (
    <div className={styles.Loader} >
        <span className={styles.Spinner} style={{
            color: props.color || '#5a5e62',
            width: `${props.size || defaultSize}rem`,
            height: `${props.size || defaultSize}rem`,
            border: `${(props.size || defaultSize) * 0.125}em solid`,
        }} />
        { props.text && <span className={styles.Text} style={{ color: props.color || '#5a5e62' }}>{props.text}</span> }
    </div>
);

export default Loader;