import React from 'react';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';

import ExportMenu from './ExportMenu/ExportMenu';
import ShareMenu from './ShareMenu/ShareMenu';
import ResolutionSelector from './ResolutionSelector/ResolutionSelector';

import { ReactComponent as UtLogo } from './images/ut-logo.svg';

import styles from './TopBar.module.css';

const TopBar = inject('store')(
    observer(
        class TopBar extends React.Component {
            render() {
                return (
                    <div className={cx(this.props.className, styles.TopBar)}>
                        <UtLogo className={styles.Logo} />
                        <div className={styles.ControlsContainer}>
                            <ExportMenu />
                            <ShareMenu />
                            <ResolutionSelector />
                        </div>
                    </div>
                );
            }
        },
    ),
);

export default TopBar;
