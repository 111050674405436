"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "between", {
  enumerable: true,
  get: function get() {
    return _utils.between;
  }
});
Object.defineProperty(exports, "callIfDefined", {
  enumerable: true,
  get: function get() {
    return _utils.callIfDefined;
  }
});
exports.updateInputWidth = exports.max = exports.min = void 0;

var _utils = require("react-calendar/dist/shared/utils");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

var isValidNumber = function isValidNumber(a) {
  return typeof a === 'number' && !isNaN(a);
};

var min = function min() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  return Math.min.apply(Math, _toConsumableArray(args.filter(isValidNumber)));
};

exports.min = min;

var max = function max() {
  for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    args[_key2] = arguments[_key2];
  }

  return Math.max.apply(Math, _toConsumableArray(args.filter(isValidNumber)));
};

exports.max = max;

var updateInputWidth = function updateInputWidth(element) {
  var span = document.createElement('span');
  span.innerHTML = element.value || element.placeholder;
  var container = element.parentElement;
  container.appendChild(span);
  element.style.width = "".concat(element.value.length, "ch");
  container.removeChild(span);
};

exports.updateInputWidth = updateInputWidth;