import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import TopBarMenu from '../TopBarMenu/TopBarMenu';
import Calendar from '../../utils/Calendar/entry';
import DatePicker from '../../utils/DatePicker/entry';
import Selector from './Selector/Selector';

import styles from './ResolutionSelector.module.css';
import { ReactComponent as ClockLogo } from './images/clock.svg';

const ResolutionSelector = inject('store')(observer(class ResolutionSelector extends Component {
    state = {
        maxDetail: 'month',
    }

    handleResolutionChange = (resolution) => {
        // Change store resolution
        this.props.store.setResolution(resolution);

        // Update DatePicker maxDetail
        switch (resolution) {
            case 'year':
                this.setState({ maxDetail: 'decade' })
                break;
            case 'month':
                this.setState({ maxDetail: 'year' })
                break;
            default:
                this.setState({ maxDetail: 'month' })
        }
    };

    calendarChange = newDates => {
        this.props.store.setTimeRange(newDates[0], newDates[1]);
    };

    manualFromChange = newFrom => {
        this.props.store.setTimeRange(newFrom, this.props.store.to)
    }

    manualToChange = newTo => {
        this.props.store.setTimeRange(this.props.store.from, newTo)
    }

    render() {
        const { from, to, resolution } = this.props.store;

        return (
            <TopBarMenu
                buttonText={`${from.toLocaleDateString('nl-NL', {year: 'numeric', month: 'numeric', day: 'numeric' })} - ${this.props.store.to.toLocaleDateString('nl-NL', {year: 'numeric', month: 'numeric', day: 'numeric' })} (${resolution})`}
                buttonIcon={ClockLogo}
                buttonWidth={275}
            >
                <div className={styles.MenuContent}>
                    <div className={styles.MenuSection}>
                        <div className={styles.MenuSectionTitle}>Resolution</div>
                        <Selector 
                            selectedValue={resolution} 
                            options={['hour', 'day', 'month', 'year']} 
                            onClick={this.handleResolutionChange} 
                        />
                    </div>
                    <div className={styles.MenuSection}>
                        <div className={styles.MenuSectionTitle}>Time Range</div>
                        <div className={styles.DateInputContainer}>
                            <div className={styles.DateInput}>
                                <DatePicker
                                    className={styles.Picker}
                                    maxDate={new Date(to)}
                                    onChange={this.manualFromChange}
                                    value={new Date(from)}
                                    locale="nl-NL"
                                />
                            </div>
                            <span className={styles.DateInputSeparator}>-</span>
                            <div className={styles.DateInput}>
                                <DatePicker
                                    className={styles.Picker}
                                    maxDate={new Date()}
                                    onChange={this.manualToChange}
                                    value={new Date(to)}
                                    locale="nl-NL"
                                />
                            </div>
                        </div>
                        <div className={styles.Calendar}>
                            <Calendar
                                onChange={this.calendarChange}
                                maxDate={new Date()}
                                selectRange
                                minDetail="decade"
                                maxDetail={this.state.maxDetail}
                                value={[from, to]}
                            />
                        </div>
                    </div>
                </div>
            </TopBarMenu>
        );
    }
}));

export default ResolutionSelector;