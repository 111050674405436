import { observable, action, computed, runInAction, makeObservable } from 'mobx';
import moment from 'moment';
import randomcolor from 'randomcolor';

import energyApi from './api/energyApi';

const prettifyName = (str) => {
    var splitStr = str.toLowerCase().split('-');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
};

const transformApiResource = (resource, name) => {
    return {
        name: name || prettifyName(resource.name),
        children: resource.energyTypes.map((energyType) => `${resource.name}/${energyType}`),
    };
};

class Store {
    // Global store
    selectedResources = {};

    constructor() {
        makeObservable(this, {
            selectedResources: observable,
            hasSelectedResources: computed,
            apiResourceData: observable,
            resourceStructure: computed,
            resourceNameData: computed,
            selectedResourcesList: computed,
            chartData: computed,
            chartAxes: computed,
            exportData: computed,
            toggleSelection: action,
            updateColor: action,
            from: observable,
            to: observable,
            resolution: observable,
            setTimeRange: action,
            setResolution: action,
            serializeView: computed,
            deserializeView: action,
        });
    }

    get hasSelectedResources() {
        return Object.entries(this.selectedResources).length !== 0;
    }

    apiResourceData = [];
    get resourceStructure() {
        if (this.apiResourceData.length > 0) {
            // Get UT resource
            const universityDefinition = transformApiResource(
                this.apiResourceData.find((resource) => resource.name === 'universiteit-twente'),
                'University of Twente',
            );

            // Get faculties
            const facultyResources = this.apiResourceData.filter(
                (resource) =>
                    resource.name.startsWith('fac-') &&
                    (resource.name.match(/-/g) || []).length === 1,
            );

            const facultyDefinition = {
                name: 'Faculties',
                children: facultyResources.map((facultyResource) =>
                    transformApiResource(
                        facultyResource,
                        facultyResource.name.split('-')[1].toUpperCase(),
                    ),
                ),
            };

            // Get buildings
            const buildingResources = this.apiResourceData.filter(
                (resource) =>
                    resource.name.startsWith('fac-') &&
                    (resource.name.match(/-/g) || []).length === 2,
            );

            const facultyResourcesPerBuilding = {};
            for (let buildingResource of buildingResources) {
                const buildingName = buildingResource.name.split('-')[2];
                if (!facultyResourcesPerBuilding[buildingName]) {
                    facultyResourcesPerBuilding[buildingName] = {
                        name: prettifyName(buildingName),
                        children: [],
                    };
                }

                const facultyName = buildingResource.name.split('-')[1];
                facultyResourcesPerBuilding[buildingName].children.push(
                    transformApiResource(buildingResource, facultyName.toUpperCase()),
                );
            }

            const buildingDefinition = {
                name: 'Buildings',
                children: Object.values(facultyResourcesPerBuilding),
            };

            return [universityDefinition, facultyDefinition, buildingDefinition];
        }

        return [];
    }

    // Resource name mapping to prettified names
    get resourceNameData() {
        let resourceNameData = {};

        if (this.apiResourceData.length > 0) {
            this.apiResourceData.forEach((resource) => {
                let resourceName = resource.name.startsWith('fac-')
                    ? resource.name.split('-')[1].toUpperCase()
                    : 'University of Twente';

                resource.energyTypes.forEach((energyType) => {
                    const resourceId = `${resource.name}/${energyType}`;
                    resourceNameData[resourceId] = {
                        name: prettifyName(energyType),
                        fullName:
                            resource.name.split('-').length < 3
                                ? `${resourceName} | ${prettifyName(energyType)}`
                                : `${resourceName} | ${prettifyName(
                                      resource.name.split('-')[2],
                                  )} | ${prettifyName(energyType)}`,
                    };
                });
            });
        }

        return resourceNameData;
    }

    get selectedResourcesList() {
        return Object.keys(this.selectedResources).map((key) => this.selectedResources[key]);
    }

    get chartAxes() {
        return this.selectedResourcesList.reduce((result, resource) => {
            if (resource.data) {
                result.add(resource.data.unit);
            }
            return result;
        }, new Set());
    }

    get chartData() {
        return {
            datasets: this.selectedResourcesList.reduce((result, resource) => {
                if (resource.data !== null) {
                    result.push({
                        label: resource.fullName,
                        yAxisID: resource.data.unit,
                        backgroundColor: resource.color,
                        borderRadius: 5,
                        data: resource.data.data.map((dataPoint) => ({
                            x: dataPoint.timestamp,
                            y: dataPoint.value,
                        })),
                    });
                }

                return result;
            }, []),
        };
    }

    get exportData() {
        return this.selectedResourcesList.reduce((result, resource) => {
            if (resource.data !== null) {
                result.push({
                    label: resource.fullName,
                    unit: resource.data.unit,
                    data: resource.data.data.map((dataPoint) => ({
                        x: dataPoint.timestamp,
                        y: dataPoint.value,
                    })),
                });
            }

            return result;
        }, []);
    }

    toggleSelection = async (resourceId) => {
        // Fetches all selection-related data (async functions, Promise.all)
        if (resourceId in this.selectedResources) {
            // Remove the selection from the current list
            delete this.selectedResources[resourceId];
        } else {
            // Add selection to the existing array
            this.selectedResources[resourceId] = {
                ...this.resourceNameData[resourceId],
                color: randomcolor({
                    luminosity: 'dark',
                    hue: ['green', 'orange'][Math.floor(Math.random() * 2)],
                }),
                data: null,
            };

            // Fetch resource data
            this.fetchResourceData(resourceId);
        }
    };

    updateColor = (resourceId, color) => {
        if (resourceId in this.selectedResources) {
            this.selectedResources[resourceId].color = color;
        }
    };

    // Time range store
    from = new Date(moment().subtract(1, 'month').startOf('day'));
    to = new Date(moment().endOf('day'));
    resolution = 'day';

    setTimeRange = (from, to) => {
        this.from = from;
        this.to = to;

        // Fetch resource data again
        this.fetchResourceData();
    };

    setResolution = (resolution) => {
        if (this.resolution !== 'year' && resolution === 'year') {
            // Scale current time range to year
            this.from = new Date(this.from.getFullYear(), 0, 1);
            this.to = new Date(this.to.getFullYear(), 11, 31, 23, 59, 59);
        }

        if (!['year', 'month'].includes(this.resolution) && resolution === 'month') {
            // Scale current time range to month
            this.from = new Date(this.from.getFullYear(), this.from.getMonth(), 1);
            this.to = new Date(this.to.getFullYear(), this.to.getMonth() + 1, 0);
        }

        this.resolution = resolution;

        // Fetch resource data again
        this.fetchResourceData();
    };

    // Share logic
    get serializeView() {
        let serializedResources = {};

        for (let [key, value] of Object.entries(this.selectedResources)) {
            serializedResources[key] = value.color;
        }

        return btoa(
            JSON.stringify([
                1, // Share format version
                serializedResources,
                this.from,
                this.to,
                this.resolution,
            ]),
        );
    }

    // TODO: Implement/test XSS protection
    deserializeView = async (view) => {
        try {
            const viewJson = JSON.parse(atob(view));

            this.from = new Date(viewJson[2]);
            this.to = new Date(viewJson[3]);
            this.resolution = viewJson[4];

            for (let [resourceId, color] of Object.entries(viewJson[1])) {
                this.selectedResources[resourceId] = {
                    ...this.resourceNameData[resourceId],
                    color,
                    data: null,
                };
            }

            this.fetchResourceData();
        } catch {
            // TODO: implement error message?
        }
    };

    // API fetch logic
    fetchResources = async () => {
        const apiResourceData = await energyApi.fetchResources();

        runInAction(() => {
            this.apiResourceData = apiResourceData;

            window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (_, key, value) => {
                if (key === 'view') {
                    this.deserializeView(value);
                }
            });
        });
    };

    fetchResourceData = async (resourceId) => {
        const resourceIds =
            resourceId !== undefined ? [resourceId] : Object.keys(this.selectedResources);

        // Call the API for each selection
        const results = resourceIds.map(async (resourceId) =>
            energyApi.fetchResource(
                resourceId,
                this.from.toISOString().replace(/\.[0-9]{3}/, ''),
                this.to.toISOString().replace(/\.[0-9]{3}/, ''),
                this.resolution,
                resourceId.startsWith('universiteit-twente'),
            ),
        );

        // Wait until all requests have completed
        const resourceData = await Promise.all(results);

        runInAction(() => {
            resourceIds.forEach((resourceId, index) => {
                if (resourceId in this.selectedResources) {
                    this.selectedResources[resourceId].data = resourceData[index];
                }
            });
        });
    };
}

export default new Store();
