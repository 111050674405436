import React, { useState } from 'react';
import cx from 'classnames';

import { ReactComponent as Arrow } from './images/arrow.svg'

import styles from './Folder.module.css';

const Folder = ({ name, children }) => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <div className={styles.Folder}>
            <div className={styles.FolderName} onClick={() => setCollapsed(!collapsed)} >
                <Arrow className={cx(styles.Arrow, { [styles.CollapsedArrow]: collapsed })} />
                { name }
            </div>
            { collapsed && (
                <div>
                    <div className={styles.Items}>
                        { children }
                    </div>
                </div>
            )}
        </div>
    )
}

export default Folder;